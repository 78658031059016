import { useEffect } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { useRouter } from 'next/router'
import { graphql, useFragment } from 'react-relay'

import NavBarTop from './NavBarTop'
import NavMobile from './NavMobile'

import type { Navigation_authorization$key } from '__generated__/Navigation_authorization.graphql'
import Hide from 'components/Hide'
import Box from 'components/Primitives/Box'
import { getJoinPageUrlPath } from 'lib/getJoinPageUrlPath'

export interface NavProps {
    isAuthenticated: boolean
    isSearchPage: boolean
    hasPremiumSubscription: boolean
    children: React.ReactNode
    authorization: Navigation_authorization$key | null
}

const CTA_SOURCE = 'header start free trial'

const Navigation = ({
    isAuthenticated,
    isSearchPage,
    hasPremiumSubscription,
    authorization: authorizationKey,
    children: pageContent,
}: NavProps) => {
    const authorization = useFragment(NavigationAuthorizationFragment, authorizationKey)

    const { asPath } = useRouter()
    const freeTrialUrl = getJoinPageUrlPath(asPath, CTA_SOURCE)
    const showPurchaseCTA = !isAuthenticated || !hasPremiumSubscription

    const isB2BUser = authorization ? authorization.roles.isB2bUser : false

    useEffect(() => {
        // This is a slightly hacky way to manage when we record session replays.
        // We only want to record session replays for B2B users.
        // If cookies haven't been accepted, the 'start' method won't do anything.
        isB2BUser && datadogRum.startSessionReplayRecording()
        // This is to cover the case where a user logs out.
        !isB2BUser && datadogRum.stopSessionReplayRecording()
    }, [isB2BUser])

    return (
        <Box
            style={{
                flexGrow: 1,
            }}
        >
            {/* only display on desktop */}
            <Hide on={[true, true, true, false]} m="0 auto">
                <NavBarTop
                    isAuthenticated={isAuthenticated}
                    showPurchaseCTA={showPurchaseCTA}
                    freeTrialUrl={freeTrialUrl}
                    isSearchPage={isSearchPage}
                    isB2BUser={isB2BUser}
                    isFixed
                />
            </Hide>
            {/* only display on non-desktop */}
            <Hide m="0 auto" on={[false, false, false, true]}>
                <NavMobile
                    isAuthenticated={isAuthenticated}
                    showPurchaseCTA={showPurchaseCTA}
                    freeTrialUrl={freeTrialUrl}
                    isSearchPage={isSearchPage}
                    isB2BUser={isB2BUser}
                />
            </Hide>
            {pageContent}
        </Box>
    )
}

export default Navigation

const NavigationAuthorizationFragment = graphql`
    fragment Navigation_authorization on Authorization {
        roles {
            isB2bUser
        }
    }
`
