import React from 'react'

import type { Hit } from 'instantsearch.js'
import { Highlight } from 'react-instantsearch-hooks-web'

import ListRow from 'components/ListRow'
import { useContentOrChapterUrl } from 'modules/ContentDisplay/utils'
import type { ContentPieceSearchResult } from 'modules/Search/types'

export type Props = {
    hit: Hit<ContentPieceSearchResult>
}

const SearchResultListRow = ({ hit }: Props) => {
    const url = useContentOrChapterUrl({
        id: hit.id,
        hasChapters: hit.hasChapters,
        chapters: hit.chapters,
        canonicalLink: hit.canonicalLink,
        slug: hit.slug,
    })

    if (!url) return null

    return (
        <ListRow
            url={url}
            imageAlt={hit.trackingTitle}
            imageSrc={hit?.headerImage?.full ?? ''}
            title={<Highlight attribute="title" hit={hit} />}
            subtitle={<Highlight attribute="subtitle" hit={hit} />}
            id={hit.objectID}
        />
    )
}

export default SearchResultListRow
