import type { CSSProperties, ReactElement } from 'react'

import { Item, List, Root, Trigger, Viewport } from '@radix-ui/react-navigation-menu'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import Image from 'components/Image'
import { LegacyLink as Link } from 'components/Link'
import NavigationItem from 'components/NavigationItem'
import Box from 'components/Primitives/Box'
import { getNavBarItems } from 'modules/Navigation/NavBarLinks'
import finimizeBrandName from 'public/images/finimize-brand-name.svg'
import finimizeLogoPrimary from 'public/images/finimize-primary-logo.svg'
import { routeMap } from 'shared/routes'
import { zIndex } from 'theme/index'

const isSelected = (url: string, asPath: string): boolean => {
    return url === asPath.split('?')[0]
}

const NavigationItems = ({ isB2BUser }: { isB2BUser: boolean }) => {
    const { asPath } = useRouter()
    const NavBarZIndex = zIndex.layer08

    return (
        <NavigationRoot
            orientation="horizontal"
            style={{
                position: 'relative',
                overflow: 'visible',
                width: '100%',
            }}
        >
            <Box
                style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: NavBarZIndex,
                    width: '100%',
                    overflow: 'visible',
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Header>
                        <NavItem>
                            <Link url={routeMap.home} display="flex" data-cy="Home-nav-item">
                                <Image
                                    src={finimizeLogoPrimary}
                                    maxWidth="30px"
                                    alt="Finimize logo icon"
                                    pt="0.5rem"
                                    alignSelf="center"
                                    priority={true}
                                />
                                <Image
                                    src={finimizeBrandName}
                                    maxWidth="100px"
                                    alt="Finimize brand name"
                                    width="35vw"
                                    ml="0.5rem"
                                    pt="0.5rem"
                                    alignSelf="center"
                                    priority={true}
                                />
                            </Link>
                        </NavItem>
                    </Header>

                    <Box flex={1}>
                        <List
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                            }}
                        >
                            {getNavBarItems({ isB2BUser }).map(header => (
                                <NavItem key={header.name}>
                                    <NavigationItem
                                        name={header.name}
                                        icon={header.icon}
                                        url={header.url}
                                        isSelected={isSelected(header.url, asPath)}
                                    />
                                </NavItem>
                            ))}
                        </List>
                    </Box>
                </Box>
            </Box>
            <Viewport />
        </NavigationRoot>
    )
}

const NavItem = ({ children, style }: { children: ReactElement; style?: CSSProperties }) => {
    return (
        <Item
            style={{
                display: 'inline-block',
            }}
        >
            <Trigger
                style={{
                    background: 'none',
                    border: 'none',
                    display: 'inline-block',
                    ...style,
                }}
            >
                {children}
            </Trigger>
        </Item>
    )
}

const NavigationRoot = styled(Root)`
    height: 100%;
    width: 100%;
    * {
        // remove list stylings (e.g. bullet points) which come with react-navigation-menu
        list-style-type: none;
    }
`

const Header = styled(Box)`
    padding-top: 0.7rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    width: max-content;
`

export default NavigationItems
