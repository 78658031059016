import { useRouter } from 'next/router'

/*

    to convert params into a link for a content piece

    if specified, it can also pull additional params (offer slug, email-id) from the url directly

*/

export function useContentPieceUrl(
    canonicalLink: string | null,
    contentId: string,
    slug?: string,
    getParamsFromUrlIfExists?: boolean,
    baseParam?: string,
): string
export function useContentPieceUrl(
    canonicalLink: string | null,
    contentId?: string,
    slug?: string,
    getParamsFromUrlIfExists?: boolean,
    baseParam?: string,
): string | null
export function useContentPieceUrl(
    canonicalLink: string | null,
    contentId?: string,
    slug = '',
    getParamsFromUrlIfExists?: boolean,
    baseParam = 'content',
): string | null {
    const router = useRouter()

    if (!contentId) {
        return null
    }

    if (canonicalLink) {
        // We just want the path of the content piece, otherwise it will
        // get treated like an external link and open in a new tab
        return parseURLPath(canonicalLink)
    }

    if (getParamsFromUrlIfExists) {
        const { query: params = {} } = router

        const offerSlugParam = params['offer-slug'] ? `offer-slug=${params.offerSlug}` : null
        const encryptedEmailParam = params['email-id'] ? `email-id=${params['email-id']}` : null

        // form extra params if they exist
        const extraParams =
            // if both then chain both
            offerSlugParam && encryptedEmailParam
                ? `?${offerSlugParam}&${encryptedEmailParam}`
                : // if either then just display that one
                offerSlugParam || encryptedEmailParam
                ? `?${offerSlugParam || encryptedEmailParam}`
                : // else no extra params needed
                  ``

        return createContentUrl({ baseParam, contentId, slug, extraParams })
    }

    return createContentUrl({ baseParam, contentId, slug })
}

export const getContentPieceUrl = useContentPieceUrl

const TRIAL_BUTTON_TEXT = 'Start Free Trial'
const NEWSLETTER_BUTTON_TEXT = 'Subscribe'
/*

    we have a _lot_ of CTAs across the app, and the copy needs to update
    if we have a newsletter user (encrypted email param)
    this centralizes that logic

*/
export const useButtonTextFromURLParams = () => {
    const { query: params = {} } = useRouter()

    // check if there's an encrypted email passed to the banner (e.g. we're a newsletter user)
    const encryptedEmailParam = params['email-id'] ? `email-id=${params['email-id']}` : null

    return encryptedEmailParam ? NEWSLETTER_BUTTON_TEXT : TRIAL_BUTTON_TEXT
}

// Abstracts the building of a content url out of the hook function so it can be used
// outside of components, ie getIntialProps
export const createContentUrl = ({
    baseParam = 'content',
    contentId,
    slug = '',
    extraParams = '',
}: {
    baseParam?: string
    contentId: string
    slug?: string
    extraParams?: string
}): string => `/${baseParam}/${contentId}/${slug}${extraParams}`

type UseContentOrChapterUrlProps = {
    id: string
    slug: string
    hasChapters: boolean
    chapters?: readonly { readonly id: string; readonly slug: string }[]
    canonicalLink: string
}

export const useContentOrChapterUrl = (contentPiece: UseContentOrChapterUrlProps) => {
    const { id, slug, hasChapters, chapters, canonicalLink: contentUrl } = contentPiece

    const urlId = hasChapters ? chapters?.[0]?.id : id
    const urlSlug = hasChapters ? chapters?.[0]?.slug : slug
    const urlBaseParam = hasChapters ? 'chapter' : undefined

    // Canonical links will not work correctly for content with chapters,
    // so we want to use the original method of creating the url
    const canonicalLink = hasChapters ? null : contentUrl

    return useContentPieceUrl(canonicalLink, urlId, urlSlug, true, urlBaseParam)
}

export const parseURLPath = (url?: string | null): string => {
    if (!url) return '/'
    const parsedURL = new URL(url)

    return parsedURL.pathname
}
