import { getCookies, clearCookie } from 'lib/useCookies'
import useSetCookieAsParam from 'lib/useSetCookieAsParam'

/*

    This hook will store any redirect query params in a cookie,
    then redirect the user back to that URL after login.

    NOT used to redirect after checkout. That logic uses the "ref-page" param.

    Run it directly on a login screen to check for redirect params

    ...Or use the redirect fn post-sign-in to perform the redirect

*/

export const loginRedirectParam = 'redirect-uri'

export const redirectIfLoginParam = (): string | null => {
    const cookies = getCookies()

    const redirectUri = loginRedirectParam in cookies ? cookies[loginRedirectParam] : undefined

    if (redirectUri) {
        clearCookie(loginRedirectParam)
    }

    return redirectUri || null
}

const useLoginRedirect = () => {
    const cookieConfig = {
        queryParam: loginRedirectParam,
        cookieExpirySeconds: 60 * 10, // Expire in 10mins
    }

    useSetCookieAsParam(cookieConfig)
}

export default useLoginRedirect
