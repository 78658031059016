import styled from 'styled-components'

import NavLogin from '../NavLogin'

import NavigationItems from './NavigationItems'

import { Button } from 'components/Button'
import Container from 'components/Container'
import Box from 'components/Primitives/Box'
import Set from 'components/Set'
import MaxWidthContainer from 'containers/MaxWidthContainer'
import { useButtonTextFromURLParams } from 'modules/ContentDisplay/utils'
import SearchButtonModal from 'modules/Search/SearchButtonModal'
import { zIndex } from 'theme'

interface NavBarTopProps {
    isAuthenticated: boolean
    isSearchPage: boolean
    freeTrialUrl: string
    showPurchaseCTA: boolean
    isB2BUser: boolean
    isFixed?: boolean
}

export const TOP_NAV_HEIGHT = '65px'

export const NavBarTop = ({
    isAuthenticated,
    showPurchaseCTA,
    freeTrialUrl,
    isSearchPage,
    isFixed,
    isB2BUser,
}: NavBarTopProps) => {
    const buttonText = useButtonTextFromURLParams()

    const isFixedProps = isFixed
        ? {
              top: 0,
              left: 0,
              right: 0,
              zIndex: zIndex.layerBase,
          }
        : { zIndex: zIndex.layer03 }

    return (
        <StyledContainer
            {...isFixedProps}
            position={isFixed ? 'sticky' : 'initial'}
            pb={[0.5, 1]}
            test-id="nav-bar-top"
            isFluid
            mx={0}
            zIndex={zIndex.layerBase}
            height={TOP_NAV_HEIGHT}
        >
            <MaxWidthContainer centraliseChildren>
                <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                    <NavigationItems isB2BUser={isB2BUser} />
                    <Set spacing={1} marginRight={1}>
                        {/* On non-Search pages, there is an icon in the navigation which opens a modal */}
                        {!isSearchPage && (
                            <Box paddingTop="0.3rem">
                                <SearchButtonModal isAuthenticated={isAuthenticated} />
                            </Box>
                        )}
                        <NavLogin isAuthenticated={isAuthenticated} iconOnly />
                        {showPurchaseCTA && (
                            <Button url={freeTrialUrl} size="small" data-cy="purchase-cta-button">
                                {buttonText}
                            </Button>
                        )}
                    </Set>
                </Box>
            </MaxWidthContainer>
        </StyledContainer>
    )
}

const StyledContainer = styled(Container)`
    background: rgba(255, 255, 255, 0.97);
    // This is hardcoded as a color vs. a variable --> but that's to achieve this specific background blur effect (design signoff)
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.palette.neutral[50]};
    margin: 0;
    padding: 0;
    z-index: ${({ theme }) =>
        theme.zIndex.layer07}; // has to be higher than zIndex of menuPortal in SearchFilter
`

export default NavBarTop
