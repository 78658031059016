import type { ReactElement, ReactFragment } from 'react'
import { useMemo } from 'react'

import styled from 'styled-components'

import MaxWidthContainer from '../containers/MaxWidthContainer'

import type { SeoProps } from './SeoWrapper'
import SeoWrapper from './SeoWrapper'

import type { Navigation_authorization$key } from '__generated__/Navigation_authorization.graphql'
import Box from 'components/Primitives/Box'
import Footer from 'modules/Footer/Footer'
import Navigation from 'modules/Navigation'
import type { ColorName } from 'theme'

type LayoutProps = {
    children: ReactElement | ReactFragment
    isAuthenticated: boolean
    isSearchPage?: boolean
    hasPremiumSubscription: boolean
    authorization: Navigation_authorization$key | null
    seoProps: SeoProps
    hideFooter?: boolean
    /**
     * If false or undefined, the page supplied MUST be wrapped in a Relay environment
     * as the Navigation component uses fragments (or the page will error).
     */
    hideNav?: boolean
    bgColor?: ColorName
}

// If you add any fragments to this component, you will need to make sure that
// ALL the pages that use it are wrapped in a Relay environment (e.g. withData)
// or they will error.
const DefaultLayout = ({
    children: pageContent,
    isAuthenticated,
    isSearchPage = false,
    hasPremiumSubscription,
    authorization,
    hideFooter = false,
    hideNav = false,
    seoProps,
    bgColor = 'neutral.0',
}: LayoutProps): React.ReactElement => {
    const PageAndFooter = useMemo(() => {
        return (
            <>
                <Box minHeight="100vh">{pageContent}</Box>
                {!hideFooter && <Footer bgColor={bgColor} />}
            </>
        )
    }, [bgColor, hideFooter, pageContent])

    return (
        <SeoWrapper seoProps={seoProps}>
            <Page>
                {!hideNav ? (
                    <Navigation
                        hasPremiumSubscription={hasPremiumSubscription}
                        isAuthenticated={isAuthenticated}
                        authorization={authorization}
                        isSearchPage={isSearchPage}
                    >
                        <MaxWidthContainer centraliseChildren>{PageAndFooter}</MaxWidthContainer>
                    </Navigation>
                ) : (
                    <>{PageAndFooter}</>
                )}
            </Page>
        </SeoWrapper>
    )
}

const Page = styled(Box)`
    min-height: 100vh;
    max-width: 100vw;
`

export default DefaultLayout
